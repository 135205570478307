<template>
  <!-- Table Container Card -->
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->

        <b-row>
          <b-col cols="12" md="12" class="mb-md-0 mb-2">
            <h3>Add Questions Category Report</h3>
            <!-- <button
              style="
                background: #7367f0;
                border-radius: 27px;
                margin-left: 10px;
                margin-top: -4px;
              "
              @click="isAddNewUserSidebarActive = true"
            >
              <span class="text-nowrap">Add Class</span>
            </button> -->
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <b-breadcrumb :items="items" /> -->

            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <date-range-picker
                ref="picker"
                :opens="'left'"
                :locale-data="{
                  direction: 'ltr',
                  format: 'yyyy-mm-dd',
                  separator: ' - ',
                  applyLabel: 'Apply',
                  cancelLabel: 'Cancel',
                  weekLabel: 'W',
                  customRangeLabel: 'Custom Range',
                  daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                  monthNames: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  firstDay: 0,
                }"
                :minDate="'2019-01-01 00:00:00'"
                :maxDate="'2099-01-01 00:00:00'"
                :timePicker="false"
                :timePicker24Hour="true"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange"
                @update="handleChange"
                :linkedCalendars="true"
              >
                <!-- <template v-slot:input="picker" style="min-width: 350px">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template> -->
              </date-range-picker>
              <!-- <v-select
                v-model="selectDate"
                @input="selectDateFunction(selectDate)"
                :options="selectDateOption"
                class="w-50"
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <span v-for="level_data in table_data" :key="level_data.Level">
        {{level_data}}
      </span> -->

      <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th>No.</b-th>
            <b-th>Category Name</b-th>
            <b-th>Total Questions</b-th>
            <b-th>Users</b-th>
            <b-th>date</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(subjectReport, index) in table_data">
            <b-tr :key="index + '111'">
              <b-td> {{ index + 1 }} </b-td>
              <!-- <b-link
                :to="{
                  path: '/categorylevels/question/' + level_data._id,
                }"
              > -->
              <b-td>
                {{ subjectReport.categroyName }}
                <!-- <div v-if="table_data[index + 1][0]">
                    {{ table_data[index + 1][0].title }}
                  </div>
                  <div v-else>No Question</div> -->
              </b-td>
              <!-- </b-link> -->

              <b-td>
                {{ subjectReport.TotalQuestions }}
                <!-- <div v-if="table_data[index + 1][0]">
                  {{ table_data[index + 1][0].questionType }}
                </div>
                <div v-else> -->
                <!-- {{ table_data[index + 1][0].title }} -->
                <!-- </div> -->
              </b-td>
              <b-td>
                <div v-for="(users, ind) in subjectReport.user" :key="ind">
                  {{ users.userName }} ({{ users.userAddQuestion }})

                  <!-- <span>{{users.userAddQuestion}} ({{}})</span> -->
                </div>
              </b-td>
              <b-td>
                {{ subjectReport.date }}
              </b-td>
              <!-- <b-td>
                <b-button
                  variant="primary"
                  @click="
                    editleveldata(
                      level_data._id,
                      level_data.levelName,
                      level_data.noOfQuestion
                    )
                  "
                >
                  <span class="text-nowrap">Edit</span>
                </b-button>
              </b-td> -->
              <!-- <b-td>
                <b-button
                  variant="danger"
                  @click="deleteleveldata(level_data._id)"
                >
                  <span class="text-nowrap">Delete</span>
                </b-button>
              </b-td> -->
            </b-tr>
          </template>

          <!-- <b-tr> -->
        </b-tbody>
      </b-table-simple>
      <!-- {{ this.table_data.level }} -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import data from "./app/data/data.json";
import DateRangePicker from "vue2-daterange-picker";

// import VueRangedatePicker from "vue-rangedate-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    BCard,
    DateRangePicker,
    BRow,
    ToastificationContent,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      table_data: {},
      levelidedit: "",
      Editlevel: "",
      EditLevelname: "",
      EditNoofquestion: "",
      dateRange: {
        start: "2018-05-14T22:00:00.000Z",
        end: "2018-05-15T21:59:00.000Z",
      },
      AddSubcategoryid: "",
      deletelevel: { delete_id: "" },
      selectDateOption: [
        "Today",
        "Yesterday",
        "This week",
        "last 7 days",
        "This month",
      ],
      selectDate: "",
      routedata: this.$route.params.categoryId,
      alertData: "",
    };
  },
  created() {
    if (localStorage.getItem("selectSubject")) {
      if (localStorage.getItem("selectSubject") != null) {
        let data = JSON.parse(localStorage.getItem("selectSubject"));
        // console.log('localgsotage',data);
        this.saveLocalData(data);
        // this.selectDate = data;
      } else {
        this.gettabledata();
      }
    } else {
      this.gettabledata();
    }
    // console.log(this.$route.params.categoryId);
    // this.gettabledata();
  },
  methods: {
    saveLocalData(value) {
      this.dateRange = value;
      // console.log("this.dateRange", this.dateRange);
      var startDate = value.startDate;
      startDate = startDate.split("T");
      // let demoDate = new Date(startDate[0]);
      // // console.log("fasasfas", demoDate);
      // var b = 1;
      // var resulta = demoDate.setDate(demoDate.getDate() + b);
      // var demo = new Date(resulta);
      // // console.log("newdate", demo);

      // var month = demo.getMonth() + 1;
      // var day = demo.getDate();
      // var year = demo.getFullYear();
      // if (day >= 10) {
      // } else {
      //   day = "0" + day;
      // }
      // if (month >= 10) {
      // } else {
      //   month = "0" + month;
      // }
      // var newdate = year + "-" + month + "-" + day;
      // console.log("newdate", newdate);
      var endDate = value.endDate;

      endDate = endDate.split("T");

      if (startDate[0] == endDate[0]) {
        var finalDate = {
          date: `${startDate[0]}`,
          id: this.$route.params.categoryId,
        };
      } else {
        var finalDate = {
          date: `${startDate[0] + " " + "to" + " " + endDate[0]}`,
          id: this.$route.params.categoryId,
        };
      }
      // console.log("finalDate", finalDate);
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
          finalDate,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.table_data = response.data;
          this.$forceUpdate();
        });
    },
    handleChange(value) {
      var storeData = JSON.stringify(value);
      localStorage.setItem("selectSubject", storeData);
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;

      if (startDate == lastDate) {
        var finalDate = {
          date: `${startDate}`,
          id: this.$route.params.categoryId,
        };
      } else {
        var finalDate = {
          date: `${startDate + " " + "to" + " " + lastDate}`,
          id: this.$route.params.categoryId,
        };
      }
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
          finalDate,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.table_data = response.data;
          this.$forceUpdate();
          // console.log("response",response)
        });
    },
    selectDateFunction(date) {
      var storeData = JSON.stringify(date);
      localStorage.setItem("selectSubject", storeData);
      // localStorage.setItem("selectSubject", date);
      if (date == "Today") {
        const today = new Date();
        // var todayDate = today.toDateString();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var year = today.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var finalDate = year + "-" + month + "-" + day;
        var datetime = {
          date: finalDate,
          id: this.$route.params.categoryId,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.table_data = response.data;
            this.$forceUpdate();
            // console.log("response",response)
          });

        // console.log("finalDate", finalDate);
      } else if (date == "Yesterday") {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        var month = yesterday.getMonth() + 1;
        var day = yesterday.getDate();
        var year = yesterday.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var finalDate = year + "-" + month + "-" + day;
        var datetime = {
          date: finalDate,
          id: this.$route.params.categoryId,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.table_data = response.data;
            this.$forceUpdate();
            // console.log("response",response)
          });

        // console.log("finalDate", finalDate);
      } else if (date == "This week") {
        var date = new Date();
        var day = date.getDay();
        var prevMonday = new Date();
        if (date.getDay() == 0) {
          prevMonday.setDate(date.getDate() - 7);
        } else {
          prevMonday.setDate(date.getDate() - (day - 1));
        }

        // console.log("prevMonday", prevMonday);
        var month = prevMonday.getMonth() + 1;
        var day = prevMonday.getDate();
        var year = prevMonday.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        // var finalDate = startDate + "to" + lastDate;
        var finalDate = {
          date: `${lastDate + " " + "to" + " " + startDate}`,
          id: this.$route.params.categoryId,
        };
        // finalDate = JSON.stringify(finalDate);
        // // console.log("2",finalDate);
        // finalDate = finalDate.replace(/ /g, "\xa0");
        // finalDate = JSON.parse(finalDate);
        // var datetime = {
        //   date: finalDate,

        // };
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
            finalDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.table_data = response.data;
            this.$forceUpdate();
            // console.log("response",response)
          });

        // console.log("finalDate", finalDate);
      } else if (date == "last 7 days") {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 6);
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var year = today.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = yesterday.getMonth() + 1;
        var day = yesterday.getDate();
        var year = yesterday.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;

        var finalDate = {
          date: `${lastDate + " " + "to" + " " + startDate}`,
          id: this.$route.params.categoryId,
        };
        // finalDate = JSON.stringify(finalDate);
        // // console.log("2",finalDate);
        // finalDate = finalDate.replace(/ /g, "\xa0");
        // finalDate = JSON.parse(finalDate);
        //   var datetime = {
        //   date: finalDate,

        // };
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
            finalDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.table_data = response.data;
            this.$forceUpdate();
            // console.log("response",response)
          });

        // console.log("finalDate", finalDate);
      } else {
        const today = new Date();
        // const yesterday = new Date(today);
        // yesterday.setDate(yesterday.getDate() - 30);
        var date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // console.log("firstDay",firstDay);
        // return 'done';
        var month = firstDay.getMonth() + 1;
        var day = firstDay.getDate();
        var year = firstDay.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var year = today.getFullYear();
        // day = '10';
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;

        var finalDate = {
          date: `${startDate + " " + "to" + " " + lastDate}`,
          id: this.$route.params.categoryId,
        };
        // console.log(typeof(finalDate));
        // finalDate = JSON.stringify(finalDate);
        // // console.log("2",finalDate);
        // finalDate = finalDate.replace(/ /g, "\xa0");
        // // console.log("finalDate",finalDate);

        // finalDate = JSON.parse(finalDate);

        //   var datetime = {
        //   date: finalDate,

        // };
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/GET_ADD_QUESTION_REPORT_CATEGORY_DATEBASE",
            finalDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.table_data = response.data;
            this.$forceUpdate();
            // console.log("response",response)
          });

        // console.log("finalDate", finalDate);
      }
    },
    gettabledata: function () {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/GET_QUESTIONS_REPORTS_CATEGORY/${this.routedata}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((get_response) => {
          // console.log(typeof(get_response.data.levels));
          this.table_data = get_response.data;
          var a = new Date();
          var b = -6;
          var resulta = a.setDate(a.getDate() + b);
          // console.log(new Date(result))
          var someDate = new Date();
          var numberOfDaysToAdd = 0;
          var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
          this.dateRange = {
            endDate: new Date(result),
            startDate: new Date(resulta),
          };
          //   console.log(this.table_data);
          //  console.log(typeof(this.table_data));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
